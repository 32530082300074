<template>
  <div v-loading="loading" class="split">
    <div class="card inline">
      <DetailsHeader :show-popver="false" />
    </div>
    <div class="card card-list">
      <el-table
        :data="tableData"
        class="table-box"
        border
        style="width: 100%"
        :header-cell-style="{ background: '#F2F5F8' }"
      >
        <el-table-column prop="name" label="科 目" width="220" align="center" />
        <el-table-column
          prop="score"
          label="满 分"
          width="220"
          align="center"
        />
        <el-table-column prop="type" label="类 别" width="220" align="center">
          <template slot-scope="{ row }">
            <span :class="row.type === 4 ? 'ft-red' : ''">{{
              getType(row.type)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="splitFromSubjectName"
          label="被拆分科目"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="{ row }">
            <span>{{ row.splitFromSubjectName || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="description" label="小题" align="center">
          <template slot-scope="{ row }">
            <div
              v-for="(item, index) in row.descriptions"
              :key="row.id + '' + index"
              style="text-align: left; font-size: 12px"
            >
              {{ item }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操 作" align="center" width="220">
          <template slot-scope="{ row }">
            <el-button v-if="row.type === 4" type="text" @click="del(row)"
              >删除拆分的科目</el-button
            >
            <el-button
              v-else-if="row.type === 1"
              type="text"
              @click="splitSubject(row)"
              >拆分科目</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import DetailsHeader from "@/components/detailsHeader.vue";
import {
  getsubjectsplitlist,
  removesubjectsplit,
} from "@/core/api/academic/statistics";
import { getObj } from "@/core/api/exam/exam";
import { subjectCategoryOptions } from "@/core/util/constdata";
export default {
  name: "Split",
  components: {
    DetailsHeader,
  },
  data() {
    return {
      loading: true,
      tableData: [],
      examInformation: {},
    };
  },
  mounted() {
    this.getData();
    this.getExamObj();
  },
  methods: {
    async getExamObj() {
      const res = await getObj(this.$route.query.examId);
      this.examInformation = res.data.data;
    },
    async getData() {
      const {
        query: { id },
      } = this.$route;
      const res = await getsubjectsplitlist({ statId: id });
      this.loading = false;
      this.tableData = res.data.data;
    },
    getType(type) {
      let data = subjectCategoryOptions.find((item) => item.value === type);
      return data.label;
    },
    splitSubject(row) {
      this.$router.push(
        `/academic/statistics/splitSubject?name=${row.name}&id=${row.id}&statId=${this.$route.query.id}&score=${row.score}&level=${this.$route.query.securityLevel}`
      );
    },
    del(row) {
      this.$confirm("确定删除拆分科目吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(async () => {
          try {
            const res = await removesubjectsplit({ id: row.id });
            if (res.data.code == 0) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getData();
            }
          } catch {
            this.getData();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.split {
}
.table-box {
  ::v-deep td {
    height: 44px;
  }
  ::v-deep th {
    height: 44px;
  }
}
</style>
